import React from 'react'
import { useSelector } from 'react-redux'

import { Authenticated } from 'layouts/authenticated'

function HomePage() {
  const user = useSelector((state) => state.authentication.user)

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 mt-3">
          <h5>
            {'Welcome, '}
            {user?.client?.first_name}
            {'!'}
          </h5>
          <p>{"You're logged in"}</p>
        </div>
      </div>
    </div>
  )
}

export default function Page() {
  return (
    <Authenticated>
      <HomePage />
    </Authenticated>
  )
}
